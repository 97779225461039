

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../CSS/main.css";
import { CiMail, CiLocationOn } from "react-icons/ci";
import { MdOutlinePhone } from "react-icons/md";
import { FaInstagram, FaFacebook, FaWhatsapp } from "react-icons/fa";
import logo from "../../Images/Common/ND-skills-transparent.png";
import {
  UnderLine,
  ScrollToTop,
  links,
  SchoolsLinks,
  HelpLinks,
} from "../utils";

const Footer = () => {
  return (
    <footer className="backGroundColor text-white mt-5 py-4 overflow-hidden">
      <Container>
        <Row>
          {/**************    Who are we     *********************/}

          <Col xs={12} md={12} lg={3} className="mb-4">
            <img
              src={logo}
              alt="ndskills logo"
              className="img-fluid pb-4"
              style={{ width: "14rem" }}
            />
            <p>
              Get job-ready skills and real-world experience to transform your
              livelihood. Start your training journey today!
            </p>
            <div className="d-flex gap-3">
              <Link
                target="_blank"
                to="https://www.facebook.com/profile.php?id=100054617311819"
                className="rounded-circle bg-white justify-content-center d-flex p-2 align-items-center socialLinks"
              >
                <FaFacebook size={18} />
              </Link>
              <Link
                target="_blank"
                to="https://www.instagram.com/newdirections_skills/"
                className="rounded-circle bg-white justify-content-center d-flex p-2 align-items-center socialLinks"
              >
                <FaInstagram size={18} />
              </Link>
              <Link
                to="https://wa.me/+918885711171"
                target="_blank"
                className="rounded-circle bg-white justify-content-center d-flex p-2 align-items-center socialLinks"
              >
                <FaWhatsapp size={18} />
              </Link>
            </div>
          </Col>

          {/**************** Useful Links ********************/}

          <Col xs={12} sm={6} md={3} lg={2} className="mb-4">
            <h6 className="fw-bolder">Useful Links</h6>
            <UnderLine />

            <ul className="navbar-nav text-white">
              {links.map((link, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    to={link.to}
                    className="nav-link btn2"
                    onClick={ScrollToTop}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>

          {/************** Our Schools & Programs    ****************/}

          <Col xs={12} sm={6} md={3} lg={2} className="mb-4">
            <h6 className="fw-bolder ">Our Schools & Programs</h6>

            <UnderLine />

            <ul className="navbar-nav text-white">
              {SchoolsLinks.map((link, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    to={link.to}
                    className="nav-link btn2"
                    onClick={ScrollToTop}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>

          {/**************** Help    ********************/}

          <Col xs={12} sm={6} md={3} lg={2} className="mb-4">
            <h6 className="fw-bolder">Help</h6>
            <UnderLine />

            <ul className="navbar-nav text-white">
              {HelpLinks.map((link, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    to={link.to}
                    className="nav-link btn2"
                    onClick={ScrollToTop}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>

          {/************** Contact Us   ****************/}
          <Col xs={12} sm={6} md={3} lg={3} className="mb-4 ">
            <h6 className="fw-bolder">Contact Us</h6>
            <UnderLine />
            <ul className="d-flex gap-2 flex-column navbar-nav text-white">
              <li>
                {" "}
                <Link
                  to="mailto:ram@ndskills.in"
                  className="text-white text-decoration-none btn2"
                >
                  <CiMail size={18} />
                  <span className="ps-2">ram@ndskills.in</span>
                </Link>
              </li>
              <li>
                <Link
                  to="tel:+91 888-57-111-71"
                  className="text-white text-decoration-none btn2"
                >
                  <MdOutlinePhone size={18} />
                  <span className="ps-2">+91 888-57-111-71</span>
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  to="https://www.google.co.in/maps/@17.4816458,78.5587343,3a,90y,211.13h,92.17t/data=!3m6!1e1!3m4!1sG_1f65Gy9td9HbmS1E-k3A!2e0!7i13312!8i6656?entry=ttu"
                  target="_blank"
                  className={`text-white text-decoration-none btn2 `}
                >
                  <CiLocationOn size={18} />
                  <span className="ps-2">
                    Plot No.59, Road No.1, Brindavan Colony, Dr. A. S. Rao
                    Nagar, ECIL, Secunderabad, Hyderabad – 62
                  </span>
                </Link>
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="mt-3 text-center">
          <Col>
            <div className="border-top border-dark-subtle mt-3 pt-3">
              <p>
                &copy; {new Date().getFullYear()} New Directions Skills. All
                Rights Reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
